import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaRegCalendarAlt, FaBook } from "react-icons/fa"
import { IoIosStar } from "react-icons/io"
import Img from "gatsby-image"
import configs from "../../site-config"

// import { Link } from "gatsby"

const exerciseTypes = {
  T1: "Main",
  supplemental: "Supplemental",
  assistance: "Assistance",
}

const Calculator = ({ data }) => {
  const [selectedTemplate, setSelectedTemplate] = useState("Select Template")
  const [goal, setGoal] = useState("Any")
  // const [trainingMaxes, setTrainingMaxes] = useState({
  //   squat: 100,
  //   deadlift: 100,
  //   benchPress: 100,
  //   press: 100,
  // })
  const [selectedDays, setSelectedDays] = useState("Any")
  const [selectedType, setSelectedType] = useState("Any")
  const [selectedMainWork, setSelectedMainWork] = useState("Any")
  const [selectedSupplementalWork, setSelectedSupplementalWork] = useState(
    "Any"
  )
  console.log("data ", data)
  let result = data.allTemplates.edges.sort((a, b) =>
    a.node.name > b.node.name ? 1 : -1
  )
  result = result.map(i => i.node)
  const availableDays = new Set()
  availableDays.add("Any")
  const types = new Set()
  types.add("Any")
  const mainWork = new Set()
  mainWork.add("Any")
  const supplementalWork = new Set()
  supplementalWork.add("Any")
  const goals = new Set()
  goals.add("Any")

  result
    .filter(i => i.goal)
    .map(item => {
      availableDays.add(item.daysPerWeek)
      types.add(item.type)
      mainWork.add(item.mainWorkName)
      supplementalWork.add(item.supplementalWorkName)
      goals.add(item.goal)
    })

  let templates = result

  templates = templates.filter(item =>
    goal === "Any" ? true : item.goal === goal
  )
  templates = templates.filter(item =>
    selectedDays === "Any" ? true : item.daysPerWeek == selectedDays
  )
  templates = templates.filter(item =>
    selectedMainWork === "Any" ? true : item.mainWorkName === selectedMainWork
  )
  templates = templates.filter(item =>
    selectedSupplementalWork === "Any"
      ? true
      : item.supplementalWorkName === selectedSupplementalWork
  )
  templates = templates.filter(item =>
    selectedType === "Any" ? true : item.type === selectedType
  )
  return (
    <Layout>
      <SEO title="5/3/1 Template Picker" />
      {/* <Header /> */}
      <div className="m-6 flex flex-col items-center">
        <h1 className="text-5xl text-center pb-4">5/3/1 Routine Picker</h1>
        <div className="bg-white text-gray-700 rounded px-2 sm:px-10 sm:py-6 text-center">
          <p>Select your filters and get recommended templates</p>
        </div>
        <div className="m-6 text-center">{`${templates.length} template${
          templates.length === 1 ? "" : "s"
        }`}</div>
        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/3 p-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Days per week
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                onChange={event => setSelectedDays(event.target.value)}
              >
                {[...availableDays].sort().map(d => (
                  <option value={d} selected={d === selectedDays}>
                    {d}
                  </option>
                ))}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Main Work
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                onChange={event => setSelectedMainWork(event.target.value)}
              >
                {[...mainWork].map(d => (
                  <option value={d} selected={d === selectedMainWork}>
                    {d}
                  </option>
                ))}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Supplemental Work
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                onChange={event =>
                  setSelectedSupplementalWork(event.target.value)
                }
              >
                {[...supplementalWork].map(d => (
                  <option value={d} selected={d === selectedSupplementalWork}>
                    {d}
                  </option>
                ))}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Type
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                onChange={event => setSelectedType(event.target.value)}
              >
                {[...types].map(d => (
                  <option value={d} selected={d === selectedType}>
                    {d}
                  </option>
                ))}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Goal
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                onChange={event => setGoal(event.target.value)}
              >
                {[...goals].sort().map(d => (
                  <option value={d} selected={d === selectedDays}>
                    {d}
                  </option>
                ))}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          {templates.map(template => (
            <div class="sm:m-6 mt-3 w-full block">
              <div className="bg-white text-gray-700 rounded shadow px-2 sm:px-10 py-6">
                <div class="sm:flex justify-between items-center">
                  <h1 className="text-gray-700 font-bold">{template.name}</h1>
                  <p class="">{template.type}</p>
                </div>
                {template.goal && (
                  <div className="mt-6">
                    <span className="mt-2 text-gray-600 font-bold">
                      {template.goal}
                    </span>
                  </div>
                )}
                <div className="flex">
                  <div className="mt-6 mr-2 flex items-center justify-start">
                    <FaRegCalendarAlt />
                    <p className="ml-2 text-gray-600 text-2xl">{`${template.weeksCount} weeks -`}</p>
                  </div>
                  <div className="mt-6 flex items-center justify-start">
                    <p className=" text-gray-600 text-2xl">{`${template.daysPerWeek} days per week`}</p>
                  </div>
                </div>
                {template.level && (
                  <div className="mt-6">
                    <span className="mt-2 text-gray-600">
                      Ideal template for a{" "}
                    </span>
                    <span className="mt-2 text-gray-600 font-bold">
                      {template.level
                        .join(", ")
                        .replace(/, ([^,]*)$/, " or $1")}
                    </span>
                    <span className="mt-2 text-gray-600"> lifter.</span>
                  </div>
                )}
                <div className="mt-6">
                  <p className="mt-2 text-gray-600">
                    {"Main Work: " + template.mainWorkName}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="mt-2 text-gray-600">
                    {"Supplemental Work: " + template.supplementalWorkName}
                  </p>
                  <div className="mt-6 flex items-center justify-end">
                    <FaBook />
                    <p className="ml-2 text-gray-600 text-3xl">{`${template.book}`}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{ backgroundColor: "#50e2ff" }}
        className="flex flex-col items-center"
      >
        <div className="ml-6 pt-12 flex items-center">
          <Img
            fluid={data.appIcon.childImageSharp.fluid}
            className="logo531 rounded-lg"
            style={{ minWidth: 50 }}
          />
          <p className="ml-5 font-bold text-4xl">{`Ultimate 5/3/1`}</p>
        </div>
        <p className="py-6 lg:py-12 text-center font-medium text-3xl">{`An easy to use app for planning and tracking 5/3/1 programs.`}</p>

        {/* <div className="ml-6 pt-12 flex "> */}
        {/* <Img fixed={data.review.childImageSharp.fixed} className="rounded-lg" /> */}
        <div
          className="flex flex-col items-start max-w-5xl rounded-lg p-10 m-6"
          style={{ backgroundColor: "white" }}
        >
          <div className="flex flex-row">
            <IoIosStar color="#FF9500" size={40} />
            <IoIosStar color="#FF9500" size={40} />
            <IoIosStar color="#FF9500" size={40} />
            <IoIosStar color="#FF9500" size={40} />
            <IoIosStar color="#FF9500" size={40} />
          </div>
          <p className="pt-2 font-bold text-gray-500 text-2xl">{`Jeff B. 07/01/2020`}</p>
          <p className="pt-5 font-bold text-3xl">{`Finally, the Perfect Workout App`}</p>
          <p className="font-medium text-3xl">{`This app gets all the little things right. Adjusts reps automatically based on your last set. Tracks 1RM across the board. Simple interface with custom templates. Automatic rest timer.`}</p>
          <div className="pt-10" />
          <p className="font-medium text-3xl">{`Alone, these things may seem trivial. But all of this, combined into one workout app, makes for an excellent product. Gone are the days of meticulously adjusting my excel sheets or struggling with unintuitive interfaces.`}</p>
        </div>
        {/* </div> */}
        <div className="downloadButtonsContainer m-8">
          <a className="playStoreLink" href={configs.playstore_link}>
            <Img
              fixed={data.playStore.childImageSharp.fixed}
              className="playStore"
            />
          </a>

          <a className="appStoreLink" href={configs.appstore_link}>
            <Img
              fixed={data.appStore.childImageSharp.fixed}
              className="appStore"
            />
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default Calculator

export const query = graphql`
  query {
    appIcon: file(relativePath: { eq: "logo_531.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appStore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    playStore: file(relativePath: { eq: "playstore.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    review: file(relativePath: { eq: "review.png" }) {
      childImageSharp {
        fixed(height: 205) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allTemplates {
      edges {
        node {
          name
          daysPerWeek
          type
          weeksCount
          supplementalWorkName
          mainWorkName
          book
          goal
          workouts {
            exercises {
              name
              type
              exerciseName
              exerciseSets {
                percentage
                reps
                type
              }
            }
            name
            week
          }
        }
      }
    }
  }
`
